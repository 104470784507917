<template>
  <v-card>
    <v-container>
      <v-row>
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      </v-row>
      <v-card-title class="pl-1 primary--text"
        >Configuración para período</v-card-title
      >
      <v-card class="mb-1">
        <v-container>
          <v-row>
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="saveConfiguracionTablasFacturacion()"
            >
              <!-- Incluir grupo familiar -->
              <v-col cols="6" md="12" sm="12">
                <v-switch
                  v-model="PidePeriodo"
                  id="switch1"
                  class="my-0 pa-0"
                  label="Período obligatorio para nombre de nueva tabla"
                ></v-switch>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
export default {
  name: "ConfiguracionTablasFacturacion",
  components: {
    GoBackBtn
  },

  data: () => ({
    isFormValid: false,
    routeToGo: "NuevaActualizacionTablaFacturacion",
    PidePeriodo: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setConfiguraciones();
  },
  methods: {
    ...mapActions({
      saveParametrosGeneralesTablasFac:
        "prestadores/saveParametrosGeneralesTablasFac",
      getParametrosGeneralesTablasFac:
        "prestadores/getParametrosGeneralesTablasFac",
      setAlert: "user/setAlert"
    }),
    async saveConfiguracionTablasFacturacion() {
      const data = {
        pidePeriodo: this.PidePeriodo
      };
      const response = await this.saveParametrosGeneralesTablasFac(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$router.push({ name: "NuevaActualizacionTablaFacturacion" });
      }
    },
    async setConfiguraciones() {
      const response = await this.getParametrosGeneralesTablasFac();
      this.PidePeriodo = response;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 0;
  margin-left: 2px;
}
</style>
